import React, { Component } from 'react';
import { oneSignalSendExternalUserId } from '../utils';
import { getI18nextLanguage, getI18nextLanguageFromPath } from '../utils/localStorage';


class OneSignal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: 'default',
    };
  }

  componentWillMount() {
    if (typeof window === "undefined") return
    this.OneSignal = window.OneSignal || [];
    this.setupOneSignal();
  }


  setupOneSignal = async () => {
    let language = await getI18nextLanguage()
    if (!language) {
      language = await getI18nextLanguageFromPath()
    }
    language = language === "lat" ? "es" : language
    window.OneSignal = window.OneSignal || [];

    window.OneSignal.push(() => {
      if (!window.OneSignal.isPushNotificationsSupported()) {
        return;
      }

      let initConfig = {
        appId: "db3581c5-4ad0-4699-95bf-7c5864c8ff77",
        autoResubscribe: true,
        persistNotification: true,
        notifyButton: {
            enable: true,
            size: 'medium', /* One of 'small', 'medium', or 'large' */
            theme: 'default', /* One of 'default' (red-white) or 'inverse" (white-red) */
            position: 'bottom-left', /* Either 'bottom-left' or 'bottom-right' */
            showCredit: false,
            text: {
              'tip.state.unsubscribed': data[language]['Subscribe to notifications'],
              'tip.state.subscribed': data[language]["You're subscribed to notifications"],
              'tip.state.blocked': data[language]["You've blocked notifications"],
              'message.prenotify': data[language]['Click to subscribe to notifications'],
              'message.action.subscribed': data[language]["Thanks for subscribing!"],
              'message.action.resubscribed': data[language]["You're subscribed to notifications"],
              'message.action.unsubscribed': data[language]["You won't receive notifications again"],
              'dialog.main.title': data[language]['Manage Site Notifications'],
              'dialog.main.button.subscribe': data[language]['SUBSCRIBE'],
              'dialog.main.button.unsubscribe': data[language]['UNSUBSCRIBE'],
              'dialog.blocked.title': data[language]['Unblock Notifications'],
              'dialog.blocked.message': data[language]["Follow these instructions to allow notifications:"]
          },
          colors: { // Customize the colors of the main button and dialog popup button
            'circle.background': '#2557b7',
            'circle.foreground': 'white'
          }
        },
        welcomeNotification: {
          disable: true
        },
        promptOptions: {
          // actionMessage: "",
          // acceptButton: "",
          // cancelButton: "",
          showCredit: false,
          slidedown: {
            // enabled: true,
            // autoPrompt: true,
            // timeDelay: 20,
            // pageViews: 3,
            prompts: [
              // {
              //   type: "push", // current types are "push" & "category"
              //   autoPrompt: true,
              //   text: {
              //     /* limited to 90 characters */
              //     actionMessage: "We'd like to show you notifications for the latest news and updates.",
              //     /* acceptButton limited to 15 characters */
              //     acceptButton: "Allow",
              //     /* cancelButton limited to 15 characters */
              //     cancelButton: "Cancel"
              //   },
              //   delay: {
              //     pageViews: 1,
              //     timeDelay: 20
              //   }
              // },
              {
                type: "category",
                enabled: true,
                autoPrompt: true,
                text: {
                  actionMessage: data[language]["category"]["actionMessage"], 
                  acceptButton: data[language]["category"]["acceptButton"],
                  cancelButton: data[language]["category"]["cancelButton"],
    
                  /* CATEGORY SLIDEDOWN SPECIFIC TEXT */
                  negativeUpdateButton: data[language]["category"]["negativeUpdateButton"],
                  positiveUpdateButton: data[language]["category"]["positiveUpdateButton"],
                  updateMessage: data[language]["category"]["updateMessage"]  
                },
                delay: {
                  pageViews: 1,
                  timeDelay: 20
                },
                categories: [
                  {
                    tag: "films_recents",
                    label: data[language]["category"]["films_recents"]
                  },
                  {
                    tag: "films_anciens",
                    label: data[language]["category"]["films_anciens"]
                  },
                  {
                    tag: "dessins_animes",
                    label: data[language]["category"]["dessins_animes"],
                  },
                  {
                    tag: "documentaires",
                    label: data[language]["category"]["documentaires"]
                  },
                ]
              }
            ]
          }
        }

      };
      window.OneSignal.push(function () {
          window.OneSignal.SERVICE_WORKER_PARAM = { scope: '/push/' };
          window.OneSignal.SERVICE_WORKER_PATH = 'push/OneSignalSDKWorker.js'
          window.OneSignal.SERVICE_WORKER_UPDATER_PATH = 'push/OneSignalSDKUpdaterWorker.js'
          window.OneSignal.init(initConfig);
      });


      // window.updateManageWebPushSubscriptionButton();
      window.OneSignal.on('subscriptionChange', function() {
        // window.updateMangeWebPushSubscriptionButton();
        oneSignalSendExternalUserId()
      });
    });
  };
  
  render() {
    return (
      <div>
      </div>
    );
  }
}
export default OneSignal;


const data = {
  es: {
    category: {
      actionMessage: "Dinos lo que te interesa y te mantendremos al día:",
      acceptButton: "Autorizar",
      cancelButton: "Bloquear",
      negativeUpdateButton:"Cancelar",
      positiveUpdateButton:"Guardar",
      updateMessage: "Actualizar tus preferencias.",
      films_recents: "Películas recientes",
      films_anciens: "Películas antiguas",
      dessins_animes: "Dibujos animados",
      documentaires: "Documentales"
    },
    'Subscribe to notifications': 'Suscripción a notificaciones',
    "You're subscribed to notifications": "Estás suscrito a las notificaciones",
    "You've blocked notifications": "Has bloqueado las notificaciones",
    'Click to subscribe to notifications': 'Haga clic para suscribirse a las notificaciones',
    "Thanks for subscribing!": "¡Gracias por suscribirte!",
    "You won't receive notifications again": "No volverás a recibir notificaciones",
    'Manage Site Notifications': 'Gestionar las notificaciones',
    'SUBSCRIBE': 'SUSCRIPCIÓN',
    'UNSUBSCRIBE': 'DESACTIVAR',
    'Unblock Notifications': 'Desbloquear notificaciones',
    "Follow these instructions to allow notifications:": "Siga estas instrucciones para permitir las notificaciones:"
  },
  en: {
    category: {
      actionMessage: "Tell us what you are interested in, we will keep you updated:",
      acceptButton: "Allow",
      cancelButton: "Block",
      negativeUpdateButton:"Cancel",
      positiveUpdateButton:"Save",
      updateMessage: "Update your preferences.",
      films_recents: "Recent movies",
      films_anciens: "Old movies",
      dessins_animes: "Cartoons",
      documentaires: "Documentaries"
    },
    'Subscribe to notifications': 'Subscribe to notifications',
    "You're subscribed to notifications": "You're subscribed to notifications",
    "You've blocked notifications": "You've blocked notifications",
    'Click to subscribe to notifications': 'Click to subscribe to notifications',
    "Thanks for subscribing!": "Thanks for subscribing!",
    "You won't receive notifications again": "You won't receive notifications again",
    'Manage Site Notifications': 'Manage Site Notifications',
    'SUBSCRIBE': 'SUBSCRIBE',
    'UNSUBSCRIBE': 'UNSUBSCRIBE',
    'Unblock Notifications': 'Unblock Notifications',
    "Follow these instructions to allow notifications:": "Follow these instructions to allow notifications:"
  },
  fr: {
    category: {
      actionMessage: "Dites-nous ce qui vous intéresse, nous vous tiendrons à informés:",
      acceptButton: "Autoriser",
      cancelButton: "Bloquer",
      negativeUpdateButton:"Annuler",
      positiveUpdateButton:"Sauvegarder",
      updateMessage: "Mettez à jour vos préférences.",
      films_recents: "Films récents",
      films_anciens: "Films anciens",
      dessins_animes: "Dessins animés",
      documentaires: "Documentaires"
    },
    'Subscribe to notifications': 'Recevoir les notifications',
    "You're subscribed to notifications": "Vous êtes abonné aux notifications",
    "You've blocked notifications": "Vous avez bloqué les notifications",
    'Click to subscribe to notifications': 'Cliquez pour vous abonner aux notifications',
    "Thanks for subscribing!": "Merci de vous être abonné !",
    "You won't receive notifications again": "Vous ne recevrez plus de notifications",
    'Manage Site Notifications': 'Gérer les notifications',
    'SUBSCRIBE': 'S\'ABONNER',
    'UNSUBSCRIBE': 'DÉSABONNEMENT',
    'Unblock Notifications': 'Débloquer les notifications',
    "Follow these instructions to allow notifications:": "Suivez ces instructions pour autoriser les notifications :"
  }
}