import React,{ useEffect, useState } from 'react';
import { Helmet, useI18next } from 'gatsby-plugin-react-i18next';
import { getI18nextLanguage } from '../../utils/localStorage';


export const SaveCurrentLanguage = () => {
    const {changeLanguage} = useI18next();
    // const { language } = useI18next();
    const [HTMLang, setHTMLLang] = useState('')
    
    useEffect(async () => {
        let pathLanguage = window.location.pathname.split("/")[1]
        const currentLanguage = await getI18nextLanguage()
        
        if (!["es","en","lat"].includes(pathLanguage)) { 
            pathLanguage = "fr" 
        }
        
        setHTMLLang(pathLanguage)
        
        if (pathLanguage !== currentLanguage) {
            changeLanguage(pathLanguage);
        }

    }, [])

    return <>
        <Helmet
            htmlAttributes={{
                lang: HTMLang,
            }}
        />
    </>
}