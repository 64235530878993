import React from 'react'
import {Alert} from 'reactstrap'

export const parseErrorMessages = (error) => {
  const messages = []
  for(let key in error){
    for (let msg in error[key]){
      messages.push(
      <Alert key={key} color="danger">
        {error[key][msg]}
      </Alert>
      )
    }
  }
  return messages
}