import React from 'react'
import styled from '@emotion/styled'
import ModalAuthLogin from './ModalAuthLogin'
import ModalAuthSignup from './ModalAuthSignup'
import { Modal } from 'reactstrap'

class ModalAuth extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      modal: false,
      auth: 'login' // login || signup
    }
    this.toggle = this.toggle.bind(this)
    this.switchAuth = this.switchAuth.bind(this)
  }

  toggle() {
    this.setState({ modal: !this.state.modal })
  }

  componentDidMount() {
    if (this.props.auth === 'signup') {
      this.setState({auth: 'signup'})
    }
  }

  switchAuth(){
    const value = this.state.auth === 'login' ? 'signup' : 'login'
    this.setState({ auth: value })
  }

  render() {
    const {auth, modal} = this.state
    const form = auth === 'login' 
    ? <ModalAuthLogin toggle={this.toggle} switchAuth={this.switchAuth}/> 
    : <ModalAuthSignup toggle={this.toggle} switchAuth={this.switchAuth}/> 

    return(
      <div>
        <div onClick={this.toggle}>
          {this.props.children}
        </div>
          <Modal centered={true} isOpen={modal} toggle={this.toggle} size={auth === 'login' ? 'sm' : 'md'} className={this.props.className} >
            {form}
          </Modal>
      </div>
    )
  }
}


export default ModalAuth



const Button = styled.button`
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
  font-size: 16px;
  font-weight: bold;
  background: #99c432;		
  margin-bottom: 20px;
  float: left;
  margin: 1px 0 20px 0;
  width: 100%;
  min-width: 20px;
  font-size: 18px;
  background-color: #2557b7;
  &:hover{
    background: #346bc2;
  }
`

const BottomSection = styled.div`
  position: absolute;
  color: #efefef;
  width: 100%;
  font-size: 13px;
  padding: 1rem;
  margin-top: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`