import React from 'react'
import styled from '@emotion/styled'
import ModalAuthLogin from './ModalAuthLogin'
import ModalAuthSignup from './ModalAuthSignup'
import {  Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { navigate } from 'gatsby'
import image from "../images/design_raining.svg"
import { Trans, withTranslation } from 'react-i18next'
import { getI18nURLPrefix, setI18nextLanguage } from '../utils/localStorage'
// import "./Modal.css"

class ModalAuthIncentive extends React.Component {

    state = {
        isOpen: true,
        shown: true,
    }
  
    async componentDidMount(){
        let shown = localStorage.getItem("shownLoginIncentive-date-v1")
        let token = localStorage.getItem("userToken")
        if (!shown && !token) {
            this.setState({shown: false})
        } else if (shown && !token){
            const milisecondsSinceSaved = new Date().getTime() - new Date(shown).getTime()
            const minuts = milisecondsSinceSaved/1000/60;
            if (minuts > 60) {
                this.setState({shown: false})
                localStorage.clear("shownLoginIncentive-date-v1")
            }
        }
    }
    
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
        localStorage.setItem("shownLoginIncentive-date-v1", new Date())
    }

    followLink = async () => {
        const prefix = await getI18nURLPrefix()
        this.toggle()
        navigate(`${prefix}user/login/`)
    }


    render() {
        if (this.state.shown) return ""
        const { isOpen } = this.state
        return(
        <div className="">
            <Modal centered={true} size="md" isOpen={isOpen} toggle={this.toggle}>
                {/* <ModalHeader> Se connecter</ModalHeader> */}
                <ModalBody> 
                    <div className="container">
                        <div className="row">
                            <div className="col-4 pt-1">
                                <img src={image} className="w-100 mt-3"/>
                            </div>
                            <div className="col">
                                <p className="font-weight-bold text-center "><Trans>Se connecter à Cinetimes</Trans></p>
                                <ul>
                                    {/* <li>Recommendations pour vous</li> */}
                                    <li><Trans>Meilleures recommendations</Trans></li>
                                    <li><Trans>Sauvegarder des films</Trans></li>
                                    {/* <li>Sauvegarder dans votre liste</li> */}
                                    <li><Trans>Notez, commentez, partagez</Trans></li>
                                    {/* <li>Guide de streaming</li> */}
                                    {/* <li>Des films gratuits</li> */}
                                    {/* <li>Sauvegarder dans votre liste</li> */}
                                    {/* <li>Films sur vos services</li> */}
                                    {/* <li>Recherche dans vos services</li> */}
                                    {/* <li>En fonction de vos souscriptions</li> */}
                                    {/* <li>Filtres en foction de ce que vous pouvez voir</li> */}
                                    {/* <li>Des centaines de films gratuits</li> */}
                                    <li>etc...</li>
                                </ul>
                            </div>
                        </div>
                    </div> 
        
                </ModalBody>
                <ModalFooter className="helsinki">
                    <span onClick={this.toggle} className="cursor-pointer font-weight-bold text-secondary"><Trans>Non merci</Trans></span>
                    <Button className="p-2" onClick={this.followLink} ><Trans>Se connecter</Trans></Button>
                </ModalFooter>
            </Modal>
        </div>
        )
    }
}


export default withTranslation()(ModalAuthIncentive)


const Button = styled.button`
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
//   font-size: 16px;
  font-weight: bold;
  background: #99c432;		
//   margin-bottom: 20px;
//   float: left;
//   margin: 1px 0 20px 0;
//   width: 100%;
//   min-width: 20px;
//   font-size: 18px;
  background-color: #2557b7;
  &:hover{
    background: #346bc2;
  }
`