import React from 'react'
import { navigate} from 'gatsby'
import {Collapse, DropdownItem, DropdownToggle, DropdownMenu, Nav, Navbar, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, Button, Dropdown} from 'reactstrap'
import ModalAuth from './ModalAuth'
import './Navigation.css'
import { getSuggestionsApi } from '../api'
import ModalAuthIncentive from './ModalAuthIncentive'
import { parseUrlParams } from '../utils'

import {Trans, useI18next, withTranslation, Link} from 'gatsby-plugin-react-i18next';
import { getI18nextLanguage, getI18nURLPrefix } from '../utils/localStorage'

const LanguageSwitcher = () => {
  const {languages, changeLanguage} = useI18next();
  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav>
        <Trans>Language</Trans>
      </DropdownToggle>
      <DropdownMenu className="pl-0 ml-0">
        {languages.map((lng) => (
              <DropdownItem 
                key={lng}
                onClick={(e) => {
                  e.preventDefault();
                  changeLanguage(lng);
                }}
                >
                  {`${lng === "fr" ?  "🇫🇷 Français" : ""}`}
                  {`${lng === "en" ?  "🇬🇧/🇺🇸 English" : ""}`}
                  {`${lng === "es" ?  "🇪🇸 Español" : ""}`}
                  {`${lng === "lat" ? "🌎 Español Latino" : ""}`}
                  

              </DropdownItem>
            ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

class Navigation extends React.Component {
  state = {
    isOpen: false,
    prefix: `/`,
    language: ``
  }

  async componentDidMount(){
    const prefix = await getI18nURLPrefix()
    const language = await getI18nextLanguage()
    this.setState({prefix,language})
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }
  render(){
    const {user, token, logout, t} = this.props
    const { prefix, language } = this.state

    let rightSide
    if (language !== "fr" && false){
      rightSide = <>
      <Button color="success"><Trans>Ajouter Film</Trans></Button>
      </>
    } else if (token && user) {
      rightSide = (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav>
          {/* {user.username} */}
          <Trans>Mon Compte</Trans>
        </DropdownToggle>
        <DropdownMenu right className="font-family-native">
          {/* <DropdownItem onClick={() => navigate('/add')}>
            Ajouter
          </DropdownItem> */}
          <DropdownItem onClick={() => navigate(`${prefix}user/${user.username}`)}>
            <Trans>Mes notes</Trans>
          </DropdownItem>
          {/* <DropdownItem onClick={() => navigate(`/setup/services`)}>
            Mes services
          </DropdownItem> */}
          <DropdownItem onClick={() => logout()}>
            <Trans>Déconnexion</Trans>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      )
    } else {
      rightSide = (
        <>
              {/* {user.username} */}
        <UncontrolledDropdown nav inNavbar >
          <DropdownToggle nav className="d-none d-lg-flex d-xl-none">
            <Trans>Mon Compte</Trans>
          </DropdownToggle>
          <DropdownMenu right className="font-family-native">
            <DropdownItem>
              <ModalAuth auth="login">
                <div><Trans>Se connecter</Trans></div>
              </ModalAuth>
            </DropdownItem>
            <DropdownItem>
              <ModalAuth auth="signup">
                <div><Trans>Créer un compte</Trans></div>
              </ModalAuth>  
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
          <NavItem className="d-flex d-lg-none d-xl-flex">
            <ModalAuth auth="login">
              <NavLink href="#">
                <Button outline color="light" size="sm"><Trans>Se connecter</Trans></Button>
              </NavLink>
            </ModalAuth>
          </NavItem>
          <NavItem className="d-flex d-lg-none d-xl-flex">
            <ModalAuth auth="signup">
              <NavLink href="#">
                <Button color="light" size="sm"><Trans>Créer un compte</Trans></Button>
              </NavLink>
            </ModalAuth>
          </NavItem>
        </>
      )
    }
    return (
      <>
      <Navbar dark expand="lg" className="navigation">
        
          <span className="d-flex flex-nowrap">
            <Link to="/" className="navbar-brand"> Cinetimes </Link>
            <span className="d-none d-sm-flex d-lg-none mobile-search"><SearchBar placeholder={t('Rechercher')}/></span>
          </span>
          <span className="d-flex flex-column">
            <NavbarToggler onClick={this.toggle} />

          </span>

            <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="mr-auto" navbar>
                  <NavItem>
                    <LanguageSwitcher/>
                  </NavItem>  
                  <NavItem>
                    <Link className="nav-link" to="/browse/"><Trans>Films</Trans></Link>
                  </NavItem>
                  <NavItem>
                    <Link className="nav-link" to="/browse/animation"><Trans>Dessins Animés</Trans></Link>
                  </NavItem>
                  <NavItem>
                    <Link className="nav-link" to="/browse/documentary"><Trans>Documentaires</Trans></Link>
                  </NavItem>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Listes
                    </DropdownToggle>
                    <DropdownMenu left>
                      <DropdownItem onClick={() => navigate('/films/netflix')}>
                        Films sur Netflix
                      </DropdownItem>
                      <DropdownItem onClick={() => navigate('/films/amazon-prime-video')}>
                        Films sur Amazon prime video
                      </DropdownItem>
                      <DropdownItem onClick={() => navigate('/films/disney-plus')}>
                        Films sur Disney plus
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <NavItem>
                    <Link className="nav-link" to="/watchlist"><Trans>Ma liste</Trans></Link>
                  </NavItem>              
                </Nav>

                  <span className="d-lg-flex d-none"><SearchBar placeholder={t('Rechercher')}/></span>
                  <span>
                    <Nav navbar>
                      {rightSide}
                    </Nav>
                  </span>
              </Collapse>
        { language === "fr" &&
          <ModalAuthIncentive />
        }
      </Navbar>
      <span className="d-lg-none d-sm-none"><SearchBar placeholder={t('Rechercher')}/></span>
      </>
      )
  }
} 

export default withTranslation()(Navigation)

class SearchBar extends React.Component {

  state = {
    query: '',
    suggestions: [], //[{id,title,slug,poster}]
  }

  handleSuggestions = async input => {
    const suggestions = await getSuggestionsApi(input)
    if (suggestions && suggestions.data.length) {
      this.setState({suggestions: suggestions.data})
    } else {
      this.setState({suggestions: []})
    }
  }

  parseUrlAndUpdateState = () => {
    const params = parseUrlParams();
    if (params.query){
      this.setState({ query: params.query });
    }
  }

  componentDidMount =() => {
    this.parseUrlAndUpdateState()
  }

  handleSubmit = async (e) => {
    e && e.preventDefault()
    let query = this.state.query.trim()
    this.setState({query: query, suggestions: []})
    const prefix = await getI18nURLPrefix()
    navigate(`${prefix}search?query=${query}`, {
      state: { query },
    })
  }

  handleChange = (e) => { 
    const input = e.target.value
    this.setState({ query: input })
    if (input && input.length > 1){
      this.handleSuggestions(input)
    } else if (!input || input.length < 1){
      this.setState({suggestions: []})
    }
  }

  render() {

    const suggestions = this.state.suggestions.map(s => {
      const query = s.query
      return (
        <DropdownItem onClick={async () => {
          await this.setState({query: query})
          this.handleSubmit()

          // navigate(`/search?query=${query}`, {
          //   state: { query },
          // })
        
        }} key={query}>{query}</DropdownItem>
      )
    })

    return (
      <form onSubmit={this.handleSubmit} className="form-inline my-0 my-lg-0"> 
        <Dropdown isOpen={!(!suggestions.length)} toggle={() => {}}>
          <DropdownToggle tag="div">
            <input 
              size="sm" 
              // style={{width: "350px"}}
              onChange={this.handleChange} 
              value={this.state.query} 
              className="form-control mr-sm-2" 
              type="search" 
              placeholder={this.props.placeholder}
              aria-label="Search" 
            /> 
          </DropdownToggle>
          {suggestions && suggestions.length 
          ? <DropdownMenu style={{maxWidth: "350px"}} className="font-family-native">
              {suggestions}
            </DropdownMenu>
          : ''
          }

        </Dropdown>
      </form>
    )
  }
}