import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {logout} from '../redux/actions'
import Navigation from './Navigation'
import { ToastContainer } from "react-toastify"
import { parseUrlParams } from '../utils'

import "react-toastify/dist/ReactToastify.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import './layout.css'

import Footer from "../components/Footer"
import { SaveCurrentLanguage } from './hooks/SaveCurrentLanguage'
import FeedbackButton from './buttons/FeedbackButton'
import { HandleCustomGa } from './hooks/HandleCustomGa'
import OneSignal from './OneSignal'

// const Footer = React.lazy(() => import("../components/Footer"));

class Layout extends React.Component {
  static propTypes = {
    token: PropTypes.string, 
    user: PropTypes.object, 
    logout: PropTypes.func,
  }

  state = {
    isWebView: false,
  }

  componentDidMount() {
    // Show webview friendly interface when param d=rnwebview.
    // But with a safeguard. If we realize after Xs that the browser is no webview, back to normal

    const params = parseUrlParams(window.location.search);

    if (params.d === 'rnwebview') {
      this.setState({isWebView: true})

      // If in 5 seconds we don't get isWebView: true in window, back to normal
      setTimeout(() => {
        if (window.isWebView !== true && !window.ReactNativeWebView) this.setState({isWebView: false})
      }, 5000)
    }


  }

  render() {

    const {token, user, children, logout} = this.props
    const {isWebView} = this.state

    return (
      <React.Fragment>
        <SaveCurrentLanguage />
        <HandleCustomGa/>
        <FeedbackButton />
        <OneSignal/>
        {
          
          isWebView ? children
          : ( 
            <>
              <Navigation token={token} user={user} logout={logout}/>
                {children}

                <Footer/> 

            </>
          )
    
        }
        {/* <Navigation token={token} user={user} logout={logout}/>
          {children}
        <Footer/> */}
        <ToastContainer autoClose={8000} position="top-center" hideProgressBar pauseOnHover/>
      </React.Fragment>
    )
  }
} 
// const Layout = ({token, user, children, logout}) => (
//   <React.Fragment>
//     {
//       window.isWebView === true 
//       ? children
//       : ( 
//         <>
//           <Navigation token={token} user={user} logout={logout}/>
//             {children}
//           <Footer/> 
//         </>
//       )

//     }
//     {/* <Navigation token={token} user={user} logout={logout}/>
//       {children}
//     <Footer/> */}
//     <ToastContainer autoClose={8000} position="top-center" hideProgressBar pauseOnHover/>
//   </React.Fragment>
// )

const mapStateToProps = state => ({
  token: state.user.token,
  user: state.user.user,
})

export default connect(mapStateToProps, {logout})(Layout)