import React,{ useEffect } from 'react';
import { getUserId } from '../../api';
import { getGuestId } from '../../utils/localStorage';

export const HandleCustomGa = () => {
    useEffect(async () => {
        let userId = await getUserId()
        if (userId == null) {
            userId = await getGuestId()
        }

        // window.ga('set', 'dimension3', userId);
        if (typeof window !==  "undefined") {
            window.ga('send', 'pageview', {
                'dimension3':  userId
              });
        }

    }, [])

    return <></>
}