import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {signup, fetchUser} from '../redux/actions'
import {parseErrorMessages} from '../utils/auth'
import {
  ModalBody, ModalHeader, ModalFooter,
  Form, FormGroup, FormFeedback, Input, Label,
  Col, Row, Spinner
} from 'reactstrap'
import { Link, navigate } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { Trans, withTranslation } from 'react-i18next'
import {toast} from 'react-toastify'


class ModalAuthSignup extends React.Component {
  static propTypes = {
    token: PropTypes.string, 
    error: PropTypes.array,
    login: PropTypes.func,
    fetchUser: PropTypes.func,
  }

  constructor(props){
    super(props)
    this.state = {
      messages: [],
      username: '',
      email: '',
      password1: '',
      password2: '',
      isLoading: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  componentDidUpdate(prevProps){
    if (prevProps.error !== this.props.error) {
      const messages = parseErrorMessages(this.props.error)
      this.setState({messages: messages})
    }
  }

  handleInputChange(event){
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState({[name]: value})
  }

  async handleFormSubmit(event) {
    event.preventDefault()
    const {email,username,password1,password2} = this.state
    const {t} = this.props
    this.setState({isLoading: true})
    await this.props.signup({email,username,password1,password2})
    if (this.props.token){
      await this.props.fetchUser(this.props.token)

      toast.success(t(`Email de confirmation envoyé`), {autoClose:false, position:"top-right"})
      toast.success(t(`Merci de valider votre adresse email`), {autoClose:false, position:"top-right"})

      trackCustomEvent({
        category: "Sign Up Form",
        action: "Signup success",
        label: `Signup success ${email}`,
      })
      // navigate("/setup/services")
      setTimeout(() =>  navigate("/"), 2000)
      // setTimeout(() => window.location.reload() , 2000) 
      await this.props.toggle()
    } else {
      toast.error(t('Une erreur est survenue'))
      trackCustomEvent({
        category: "Sign Up Form",
        action: "Signup failed",
        label: `Signup failed ${email}`,
      })
    }
    this.setState({isLoading: false})
  }

  render() {
    const {isLoading} = this.state
    const {t} = this.props
    return(
      <div>
        <ModalHeader toggle={this.props.toggle}><Trans>Créer un compte</Trans></ModalHeader>
        <ModalBody className="pb-0">
          <Form onSubmit={this.handleFormSubmit}>
            {this.state.messages}
            <FormGroup>
              <Label for="username"><Trans>Nom</Trans></Label>
              <Input name="username" id="username" placeholder={t("Nom")} value={this.state.username} onChange={this.handleInputChange}/>
              <FormFeedback valid>Sweet! that name is available</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="email"><Trans>Email</Trans></Label>
              <Input name="email" id="email" type="email" placeholder={t("Email")} value={this.state.email} onChange={this.handleInputChange}/>
              <FormFeedback valid>Sweet! that name is available</FormFeedback>
            </FormGroup>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="password1"> <Trans>Mot de passe</Trans></Label>
                  <Input name="password1" id="password1" type="password" placeholder={t("Mot de passe")} value={this.state.password1} onChange={this.handleInputChange}/>
                  <FormFeedback valid>Sweet! that name is available</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="password2"><Trans>Confirmez mot de passe</Trans></Label>
                  <Input name="password2" id="password2" type="password" placeholder={t("Confirmez mot de passe")} value={this.state.password2} onChange={this.handleInputChange}/>
                  <FormFeedback valid>Sweet! that name is available</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <button type="submit" className="d-none"></button>   
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex flex-column">
          <Button type="submit" onClick={this.handleFormSubmit} className="btn btn-primary btn-lg mb-0">
            {isLoading && <Spinner size="sm"/> || <Trans>Créer un compte</Trans>}
          </Button>
          <span className="font-weight-lighter text-muted small text-center">
            <Trans>En continuant, vous confirmez que vous acceptez les</Trans>
            <Link to="/help/mentions-legales/"> <Trans>conditions d'utilisation</Trans> </Link>
            <Trans>et</Trans> 
            <Link to="/help/mentions-legales/"> <Trans>la politique de confidentialité</Trans>.</Link>
          </span>
        </ModalFooter>
        <BottomSection>
          <span onClick={this.props.switchAuth} className="btn btn-link btn-sm text-light mr-1 p-0"><Trans>Connexion</Trans></span>
          <span> · </span>
          <span className="btn btn-link btn-sm text-light p-0 ml-1" onClick={this.props.toggle}><Trans>Fermer</Trans></span>
        </BottomSection> 
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.user.token,
  error: state.user.signupError,
})

export default connect(mapStateToProps, {signup, fetchUser})(withTranslation()(ModalAuthSignup))



const Button = styled.button`
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
  font-size: 16px;
  font-weight: bold;
  background: #99c432;		
  margin-bottom: 20px;
  float: left;
  margin: 1px 0 20px 0;
  width: 100%;
  min-width: 20px;
  font-size: 18px;
  background-color: #2557b7;
  &:hover{
    background: #346bc2;
  }
`

const BottomSection = styled.div`
  position: absolute;
  color: #efefef;
  width: 100%;
  font-size: 13px;
  padding: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`